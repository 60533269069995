<template>
  <div>
    <!-- Call Tracking Details -->
    <div class="vx-row">
      <div class="vx-col w-full mt-8">
        <vx-card class="call-getting">
          <div class="card-title flex-wrap">
            <h2 class="mb-2 text-center">Call Tracking</h2>
            <h3 class="text-center">See which advertising sources generate phone calls...</h3>
            <p class="text-center">Have you ever wondered which of your advertising campaigns actually get people to pick up the phone and call? We have spent so much time focusing our efforts on watching our online conversion efforts, that we have missed a significant metric of our advertising efforts, “the phone call”. At first, this doesn’t seem like a big deal, but the ability to understand how your callers find you can result in huge gains in understanding the return on investment from your marketing efforts. With call tracking you can now see which campaigns are generating calls. Follow the steps below to get started:</p>
          </div> 
          <div class="call-history">
            <div class="call-btn"><a href="#" title="Start Call Tracking Today!" class="bg-primary">Start Call Tracking Today!</a></div>
            <ul>
                <li>
                    <div class="history-title bg-first-gradient">
                        <feather-icon icon="TagIcon" class="border-primary"/>
                        <h3>Create Tracking Phone Number</h3>
                    </div>
                    <div class="history-title bg-first-gradient history-info">
                        <p class="text-white">Follow our easy to use wizard to create a new call tracking phone number. <a href="#" v-if="!this.$store.state.isReseller">Click here to download our documentation.</a></p>
                        <img src="../../assets/images/createtrackingnumber.png"/>
                    </div>
                </li>
                <li>
                    <div class="history-title bg-second-gradient">
                        <feather-icon icon="CheckIcon" class="border-second"/>
                        <h3>Install Call Tracking Code on your Site</h3>
                    </div>
                    <div class="history-title bg-second-gradient history-info">
                        <p class="text-white"><a href="#">Install or update</a> our javascript Installation Code on your website. This will dynamically replace your existing phone number on your site with your newly created call tracking phone number (depending on your selected options).</p>
                        <img src="../../assets/images/installation-script.png"/>
                    </div>
                </li>
                <li>
                    <div class="history-title bg-third-gradient">
                        <feather-icon icon="AlertOctagonIcon" class="border-third"/>
                        <h3>Test, Review and Tag</h3>
                    </div>
                    <div class="history-title bg-third-gradient history-info">
                        <p class="text-white">Listen, rate, and tag your calls. Use this to rate the quality of calls from your campaigns, provide coaching to your staff, group call types, and more.</p>
                        <img src="../../assets/images/calltracking.png"/>
                    </div>
                </li>
            </ul>
            <div class="call-btn"><a href="#" title="Make More Sales" class="bg-primary">Make More Sales</a></div>
            <div class="go-call-track"><router-link :to="{name: 'Numbers'}">Get started by setting up a call tracking number!</router-link></div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

export default {
  components: {
    VxCard  
  },
};
</script>
